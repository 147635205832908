function getWebpUrl(url) {
	let formatImages = ['png', 'jpeg', 'jpg', 'gif'];
	formatImages.forEach(format => {
		if(url.toLowerCase().substr(-3) == format) {
			url = url.substring(0, url.length - 3) + 'webp';
		} else if(url.substr(-3) == format) {
			url = url.substring(0, url.length - 4) + 'webp';
		}
	})
	return url;
}

if(window.innerWidth > 991) {
	let mainImage = document.querySelector('.js-services-image');
	if(mainImage) {
		mainImage.querySelector('source').setAttribute('srcset', getWebpUrl(document.querySelectorAll('.services-items__item')[0].getAttribute('data-img')))
		mainImage.querySelector('img').setAttribute('srcset', document.querySelectorAll('.services-items__item')[0].getAttribute('data-img'))
		document.querySelectorAll('.services-items__item').forEach(item => {
			item.addEventListener('mouseover', () => {
				let src = item.getAttribute('data-img');
				document.querySelector('.services-items__item_active').classList.remove('services-items__item_active');
				item.classList.add('services-items__item_active');
				mainImage.querySelector('source').setAttribute('srcset', getWebpUrl(src))
				mainImage.querySelector('img').setAttribute('src', src)
			})
		})
	}
}
