class CalculationHeightOffer {

	constructor() {
		this.el = document.querySelector('.offer__content-container');
		this.set();
	}

	calc() {
		let height = window.innerHeight - document.querySelector('.header_static').offsetHeight;
		return height > 512 ? height : 512;
	}

	set() {
		if(window.innerWidth < 513) {
			this.el.style.height = this.calc() + 'px'	
		} else {
			this.el.removeAttribute('style');
		}
	}

}

if(document.querySelector('.offer__content-container')) {
	const calculationHeightOffer = new CalculationHeightOffer;

	window.addEventListener('orientationchange', () => {
		setTimeout(() => {
			calculationHeightOffer.set();
		}, 300)
	})
}
