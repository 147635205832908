class Forms {

	constructor() {
		this.forms = document.querySelectorAll('form');
		this.init();
	}

	validation(form) {
		let error = false;
		form.querySelectorAll('input').forEach(input => {
			if(input.getAttribute('type') == 'text') {
				if(input.value == '') {
					input.classList.add('error');
					error = true;
				} else {
					input.classList.remove('error');
				}
			} else {
				if(input.checked) {
					input.classList.remove('error');
				} else {
					input.classList.add('error');
					error = true;
				}
			}
		})
		return error;
	}

	submit() {
		this.reset();
	}

	reset(form) {
		form.reset();
	}

	init() {
		this.forms.forEach(form => {
			form.addEventListener('submit', subm => {
				let validation = this.validation(form);

				if(validation === false) {
					this.submit();
				} else {
					subm.preventDefault();
				}

			})
		});
	}
}

const forms = new Forms;