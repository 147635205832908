
import Swiper, {Navigation, Pagination, EffectFade} from "swiper";
Swiper.use([Navigation, Pagination, EffectFade]);
if(document.querySelector('.gallery')) {
	function getWebpUrl(url) {
		let formatImages = ['png', 'jpeg', 'jpg', 'gif'];
		formatImages.forEach(format => {
			if(url.toLowerCase().substr(-3) == format) {
				url = url.substring(0, url.length - 3) + 'webp';
			} else if(url.substr(-3) == format) {
				url = url.substring(0, url.length - 4) + 'webp';
			}
		})
		return url;
	}

	function createElementsSliders(countVideoSlider, countImageSlider) {
		let i = 1;
		let s = 1;
		let cl;
		let galleryImageContainer = document.querySelector('#slider-image');
		if(galleryImage) {
			galleryImage.forEach((item) => {
				cl = i == 1 || i == 8 || i == 9 ? 'gallery-group__col_big' : 'gallery-group__col_small';
				if(i == 1) {
					galleryImageContainer.querySelector('.swiper-wrapper').insertAdjacentHTML('beforeEnd', '<div class="gallery__slide swiper-slide"></div>');
					galleryImageContainer.querySelector('.swiper-slide:nth-child(' + s + ')').insertAdjacentHTML('beforeEnd', '<div class="gallery-group"></div>');
					galleryImageContainer.querySelector('.swiper-slide:nth-child(' + s + ')').querySelector('.gallery-group').insertAdjacentHTML('beforeEnd', '<div class="gallery-group__row"></div>');
				}
				galleryImageContainer.querySelector('.swiper-slide:nth-child(' + s + ')').querySelector('.gallery-group__row').insertAdjacentHTML('beforeend', '<div class="gallery-group__col '+ cl + '"><a data-fancybox="gallery" data-src="'+ item[1] +'"><picture> <source srcset="'+ getWebpUrl(item[0]) +'" type="image/webp"><img src="'+ item[0] +'"></picture></a></div>')
				i ++ ;
				if(i > countVideoSlider) {
					i = 1;
					s ++ ;
				}
			})
		}
		s = 1;
		i = 1;
		let galleryVideoContainer = document.querySelector('#slider-video');
		if(galleryVideo) {
			galleryVideo.forEach((item) => {
				if(i == 1) {
					galleryVideoContainer.querySelector('.swiper-wrapper').insertAdjacentHTML('beforeEnd', '<div class="gallery__slide swiper-slide"></div>');
					galleryVideoContainer.querySelector('.swiper-slide:nth-child(' + s + ')').insertAdjacentHTML('beforeEnd', '<div class="gallery-group"></div>');
					galleryVideoContainer.querySelector('.swiper-slide:nth-child(' + s + ')').querySelector('.gallery-group').insertAdjacentHTML('beforeEnd', '<div class="gallery-group__row"></div>');
				}
				galleryVideoContainer.querySelector('.swiper-slide:nth-child(' + s + ')').querySelector('.gallery-group__row').insertAdjacentHTML('beforeend', '<div class="gallery-group__col gallery-group__col_small"><a class="js-video" href="'+ item[1] +'"><div class="gallery-group__play"> <svg class="icon icon-play "><use xlink:href="'+ siteUrl + '/dist/images/sprite.svg#play"></use></svg></div><picture> <source srcset="'+ getWebpUrl(item[0]) + '" type="image/webp"><img src="'+ item[0] +'"></picture></a></div>')
				i ++ ;
				if(i > countImageSlider) {
					i = 1;
					s ++ ;
				}
			})
		}
	}

	if(window.innerWidth > 991){
		document.querySelectorAll('.gallery__slider').forEach((item, key) => {
			item.classList.add('gallery__slider_d');
		})
		createElementsSliders(12, 15);
	} else {
		document.querySelectorAll('.gallery__slider').forEach((item, key) => {
			item.classList.add('gallery__slider_m');
		})
		createElementsSliders(6, 6);
	}	

	let sliders = [];
	document.querySelectorAll('.gallery__slider').forEach((slider, index) => {
		sliders[index] = new Swiper(slider, {
			speed : 400,
			slidesPerView : 'auto',
			loop : slider.querySelectorAll('.gallery__slide').length > 2 &&  window.innerWidth > 991 ? true : false,
			centeredSlides : window.innerWidth < 992 ? false : true,
			navigation : {
				nextEl : slider.querySelector('.gallery__btn_next'),
				prevEl : slider.querySelector('.gallery__btn_prev')
			},
			pagination : {
				el : slider.querySelector('.gallery__progress'),
				type : 'progressbar'
			},
			on : {
				init : function() {
					if(window.innerWidth > 991) {
						slider.classList.add('gallery__slider_d');
					} else {
						slider.classList.add('gallery__slider_m');
					}
					slider.querySelector('.gallery-item-slider-index__all').innerHTML = '0' + this.slides.length;
				},
				slideChange : function() {
					if(slider.querySelector('.gallery-item-slider-index__current')) {
						slider.querySelector('.gallery-item-slider-index__current').innerHTML = this.realIndex + 1 < 10 ? '0' + (this.realIndex + 1) : this.realIndex + 1;
					}
				}, 
				resize : function() {
					slider.querySelector('.gallery-item-slider-index__all').innerHTML = '0' + this.slides.length;
				}
			}
		});
	})

	document.querySelectorAll('.gallery__tab').forEach(item => {
		item.addEventListener('click', () => {
			let attr = item.getAttribute('data-href');
			document.querySelectorAll('.gallery__tab').forEach(tab => {
				tab.classList.remove('gallery__tab_active');
			})
			item.classList.add('gallery__tab_active');
			document.querySelectorAll('.gallery__slider').forEach(slider => {
				slider.style.display = 'none';
			})
			$('#' + attr).fadeIn(300);
		})
	})

	window.addEventListener('resize', () => {
		if(window.innerWidth > 991 && !document.querySelector('.gallery__slider').classList.contains('gallery__slider_d')) {
			document.querySelectorAll('.gallery__slider').forEach((item, key) => {
				item.classList.add('gallery__slider_d');
				item.classList.remove('gallery__slider_m');
				item.querySelector('.swiper-wrapper').innerHTML = ' ';
				createElementsSliders(1, 15);
				setTimeout(() => {
					sliders[key].update();
				}, 300)
			});
		} else if(window.innerWidth < 992 && !document.querySelector('.gallery__slider').classList.contains('gallery__slider_m')) {
			document.querySelectorAll('.gallery__slider').forEach((item, key) => {
				item.classList.add('gallery__slider_m');
				item.classList.remove('gallery__slider_d');
				item.querySelector('.swiper-wrapper').innerHTML = ' ';
				createElementsSliders(6, 6);
				setTimeout(() => {
					sliders[key].update();
				}, 300)
			});
		} 
	})

	$(document).ready(function() {
		$("a.scrollto").click(function() {
			var elementClick = $(this).attr("href")
			var destination = $(elementClick).offset().top - 30;
			jQuery("html:not(:animated),body:not(:animated)").animate({
				scrollTop: destination
			}, 800);
			return false;
		});
	});
}