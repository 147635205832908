class CalculationRem {

	constructor() {
		this.el = document.documentElement;
		this.set();
	}

	calc() {
		let width = window.innerWidth;
		let fontSize;

		fontSize = width / 1920 * 17;
		
		if(fontSize < 14) {
			fontSize = 14;
		}

		return fontSize; 
	}

	set() {
		this.el.style.fontSize = this.calc() + 'px';
	}
}

const calculationRem = new CalculationRem;

window.addEventListener('resize', () => {
	calculationRem.set();
})
