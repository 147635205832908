import {Carousel, Fancybox, Panzoom} from "@fancyapps/ui"

Fancybox.bind(".js-modal", {
  infinite: false,
	dragToClose: false,
	hideClass: 'fancybox-zoomOutDown'
});

Fancybox.bind(".js-video", {
  Html: {
    youtube: {
      autohide: 1,
			fs: 1,
			rel: 0,
			hd: 1,
			wmode: "transparent",
			enablejsapi: 1,
			html5: 1,
    },
  }
});

const buttonModalInputChange = document.querySelector('.modal__change-input');
if(buttonModalInputChange) {

	buttonModalInputChange.addEventListener('click', () => {
		let input = document.querySelector('.js-input-change');
		if(input.getAttribute('name') == 'phone') {
			input.setAttribute('name', 'email');
			input.setAttribute('placeholder', 'E-mail');
			buttonModalInputChange.textContent = 'Я хочу указать телефон';
		} else {
			input.setAttribute('name', 'phone');
			input.setAttribute('placeholder', 'Номер телефона');
			buttonModalInputChange.textContent = 'Я хочу указать e-mail';
		}
	})
	
}