import Swiper, {Navigation, Pagination, EffectFade} from "swiper";
Swiper.use([Navigation, Pagination, EffectFade]);

if(window.innerWidth < 1301) {
	$('.portfolio-item__slider').removeAttr('dir')
}

function editFormatPagination(el) {
	el.innerHTML = el.innerHTML < 10 ? '0' + el.innerHTML : el.innerHTML;
}

let mainSliderEl = document.querySelector('.portfolio__slider');
let mainSlider = new Swiper(mainSliderEl, {
	slidesPerView : 1,
	rewind : true,
	effect : 'fade',
	simulateTouch: true,
  fadeEffect : {
    crossFade: true
  },
	navigation : {
		nextEl : document.querySelector('.portfolio__btn_next'),
		prevEl : document.querySelector('.portfolio__btn_prev')
	},
	pagination : {
		el : document.querySelector('.portfolio__pagination'),
		type : 'fraction',
		renderFraction : function (currentClass, totalClass) {
			return '<span class="' + currentClass + '"></span>' +
					'/' +
					'<span class="' + totalClass + '"></span>';
		},
	},
	on : {
		init : function() {
			editFormatPagination(mainSliderEl.querySelector('.swiper-pagination-total'));
			editFormatPagination(mainSliderEl.querySelector('.swiper-pagination-current'));
		}, 
		slideChange : function() {
			editFormatPagination(mainSliderEl.querySelector('.swiper-pagination-total'));
			editFormatPagination(mainSliderEl.querySelector('.swiper-pagination-current'));
		}
	}
})

let sliders = [];
document.querySelectorAll('.portfolio-item__slider').forEach((slider, index) => {
	sliders[index] = new Swiper(slider, {
		speed : 400,
		slidesPerView : 'auto',
		rewind : window.innerWidth < 991 ? false : true,
		navigation : {
			nextEl : slider.querySelector('.portfolio-item__slider-btn_next'),
			prevEl : slider.querySelector('.portfolio-item__slider-btn_prev')
		},
		pagination : {
			el : slider.querySelector('.portfolio-item__slider-progress'),
			type : 'progressbar'
		},
		on : {
			slideChange : function() {
				slider.querySelector('.portfolio-item-slider-index__current').innerHTML = this.activeIndex + 1 < 10 ? '0' + (this.activeIndex + 1) : this.activeIndex + 1;
			}
		}
	});
})