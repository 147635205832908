import gsap from 'gsap'

function animateItems() {
	let scroll = window.pageYOffset;
	let offsetTopV = window.innerWidth < 991 ? 300 : 300
	document.querySelectorAll('.js-animate').forEach(item => {
		if((item.offsetTop - offsetTopV < scroll && item.offsetTop + item.offsetHeight - 100) > scroll) {
			item.classList.add('animated');
			let titles = item.querySelectorAll('.js-title');
			titles.forEach(title => {
				if(title && !title.classList.contains('js-title-active')) {
					let titleSpan = title.querySelectorAll('span');
					let h1 = new TimelineMax();
	
					title.classList.add('js-title-active');
	
					h1.staggerFromTo(titleSpan, 0.3, 
					{ease: Back.easeOut.config(0), opacity: 0, bottom: -50},
					{ease: Back.easeOut.config(0.2), opacity: 1, bottom: 0}, 0.018);
	
					setTimeout(() => {
						title.querySelectorAll('strong').forEach(strong => {
							strong.classList.add('animated');
						});
					}, 1400)
	
				} 
			})
		}
	})
}

if(window.innerWidth)
$('.js-title.h2').lettering();

let advantages = document.querySelectorAll('.advantages__col_small');
if(advantages.length > 0) {
	let d = 0;
	advantages.forEach(advantage => {
		advantage.style.transitionDelay = d + 'ms';
		d = d + 200;
	})
}

window.addEventListener('scroll', () => {
	animateItems();
})

animateItems();