import gsap from 'gsap'

class Menu {
	constructor() {
		this.buttonClose = document.querySelector('.menu__back');
		this.init();
	}

	open() {
		gsap.to('.menu', {
			opacity: 1, 
			visibility: 'visible', 
			duration: 0.3, 
			scale: 1,
			ease: "power3.out",
		})
		document.body.style.overflow = 'hidden';
		document.body.style.position = 'relative';
		document.body.style.height = '100%';
		if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			document.body.style.marginRight = '17px';
		}
	}

	close() {
		gsap.to('.menu', {
			duration: 0.2, 
			opacity: 0,
			ease: "power3.in",
			scale: 0.5,
		})
		gsap.to('.menu', {
			duration: 0,
			delay: 0.2,
			visibility: 'hidden'
		})
		document.body.style.overflow = 'unset';
		if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			document.body.style.marginRight = '0px';
		}
	}

	init() {
		document.querySelectorAll('.header__hamburger').forEach(button => {
			button.addEventListener('click', this.open);
		});
		this.buttonClose.addEventListener('click', this.close);
	}
}
if(document.querySelector('.menu')) {
	let menu = new Menu();

	$('a.scrollto').click(function(){
		menu.close();
	})
}