// Vendor
import '../../vendor/vendor'

// Components
import '../../components/components'

function setHeightInnerPage() {
	if(!document.querySelector('.inner-page')) return false;
	let elLeft = document.querySelector('.inner-page__left');
	let elRight = document.querySelector('.inner-page__right');
	let h = window.innerHeight;
	elLeft.style.height = h + 'px';
	elRight.style.height = h + 'px';
}

setHeightInnerPage()

window.addEventListener('resize', setHeightInnerPage)